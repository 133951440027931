<template>
  <div align="center">
    <h1>{{ $t('ChoosePlan') }}</h1>
    <p>{{ $t("PayHint") }}</p>
    <el-row :gutter="10">
      <!-- <el-col :xs="12" :sm="12" :lg="12">
        <el-card class="plan-card-daily" shadow="hover">
          <img :src="VIP" alt="VIP Image">
          <h3>{{ $t('DailyPlan') }}</h3>
          <p id="daily-price">$0.19</p>
          <p><small>{{ $t('DailyPlanHint') }}</small></p>
          <el-button type="primary" @click="openCheckout('pri_01htv35tn5a1sznaqf14yzns3x')">{{ $t('Pay') }}</el-button>
        </el-card>
      </el-col> -->
      <el-col :xs="12" :sm="12" :lg="12">
        <el-card class="plan-card-daily" shadow="hover">
          <img :src="VIP" alt="VIP Image">
          <h3>{{ $t('MonthlyPlan') }}</h3>
          <p id="daily-price">$0.7{{ $t('MonthlyPlanTime')}}</p>
          <p><small>{{ $t('MonthlyPlanHint') }}</small></p>
          <el-button type="primary" @click="openCheckout('pri_01hv4c2h4v44kqtmp9gjr8wdqh')">{{ $t('Pay') }}</el-button>
        </el-card>
      </el-col>
      <el-col :xs="12" :sm="12" :lg="12">
        <el-card class="plan-card-annual" shadow="hover">
          <img :src="VIP" alt="VIP Image">
          <h3>{{ $t('AnnualVIP') }}</h3>
          <p id="starter-price">$3.99{{ $t('AnnualVIPTime')}}</p>
          <p><small>{{ $t('AnnualVIPHint') }}</small></p>
          <el-button type="danger" @click="openCheckout('pri_01hv4c3628bf2k8908qse650v3')">{{ $t('Subscribe') }}</el-button>
        </el-card>
      </el-col>
      <el-col :xs="12" :sm="12" :lg="12">
        <el-card class="plan-card-monthly" shadow="hover">
          <img :src="VIP" alt="VIP Image">
          <h3>{{ $t('MonthlyVIP') }}</h3>
          <p id="daily-price">$0.29{{ $t('MonthlyVIPTime')}}</p>
          <p><small>{{ $t('MonthlyVIPHint') }}</small></p>
          <el-button type="primary" @click="openCheckout('pri_01hv4c2h4v44kqtmp9gjr8wdqh')">{{ $t('Subscribe') }}</el-button>
        </el-card>
      </el-col>
      <el-col :xs="12" :sm="12" :lg="12">
        <el-card class="plan-card-quartely" shadow="hover">
          <img :src="VIP" alt="VIP Image">
          <h3>{{ $t('QuartelyVIP') }}</h3>
          <p id="starter-price">$0.99{{ $t('QuartelyVIPTime')}}</p>
          <p><small>{{ $t('QuartelyVIPHint') }}</small></p>
          <el-button type="success" @click="openCheckout('pri_01hv4c43x14rh9752e6yxmrczc')">{{ $t('Subscribe') }}</el-button>
        </el-card>
      </el-col>
    </el-row>
    <p>
      Copyright © emperinter
    </p>
  </div>
</template>

<script>
import VIP from '@/assets/vip.png';

export default {
  name: 'PricePage',
  data() {
    return {
      isSubscribed: false,
      subscriptionExpiration: null,
      VIP: VIP
    };
  },
  mounted() {
    const purchaseStatus = localStorage.getItem("purchaseStatus");
    const expirationTime = localStorage.getItem("expirationTime");
    if (purchaseStatus === "purchased" && expirationTime && new Date() < new Date(expirationTime)) {
      this.isSubscribed = true;
      this.subscriptionExpiration = new Date(expirationTime).toLocaleString();
    }else{
      this.isSubscribed = false;
      this.subscriptionExpiration = new Date().toLocaleString();
      localStorage.clear;
    }
  },
  methods: {
    openCheckout(priceId) {
      // eslint-disable-next-line no-undef
      // Paddle.Environment.set("sandbox");

      // eslint-disable-next-line no-undef
      Paddle.Initialize({
        // token: "test_f4c61ffd10ba901196a31fa938b",
        token: "live_2a60174d354524c31a74f588161",
        eventCallback: function (data) {
          if (data.name == "checkout.completed") {
            if (data.data.items[0].price_name == "DailyPlan") {
              const expirationTime = new Date();
              // One Day
              expirationTime.setDate(expirationTime.getDate() + 1); // Set expiration time to one day from now
              localStorage.setItem("purchaseStatus", "purchased");
              localStorage.setItem("price_name", "DailyPlan");
              localStorage.setItem("expirationTime", expirationTime.toISOString());
            }else if(data.data.items[0].price_name == "MonthlyPlan"){
              const expirationTime = new Date();
              expirationTime.setDate(expirationTime.getDate() + 31); // Set expiration time to one day from now
              localStorage.setItem("purchaseStatus", "purchased");
              localStorage.setItem("price_name", "MonthlyPlan");
              localStorage.setItem("expirationTime", expirationTime.toISOString());
            }else if(data.data.items[0].price_name == "MonthlyVIP"){
              const expirationTime = new Date();
              expirationTime.setDate(expirationTime.getDate() + 31); // Set expiration time to one day from now
              localStorage.setItem("purchaseStatus", "purchased");
              localStorage.setItem("price_name", "MonthlyVIP");
              localStorage.setItem("expirationTime", expirationTime.toISOString());
            }else if(data.data.items[0].price_name == "QuartelyVIP"){
              const expirationTime = new Date();
              expirationTime.setDate(expirationTime.getDate() + 93); // Set expiration time to one day from now
              localStorage.setItem("purchaseStatus", "purchased");
              localStorage.setItem("price_name", "QuartelyVIP");
              localStorage.setItem("expirationTime", expirationTime.toISOString());
            }else if(data.data.items[0].price_name == "AnnualVIP"){
              const expirationTime = new Date();
              expirationTime.setDate(expirationTime.getDate() + 365); // Set expiration time to one day from now
              localStorage.setItem("purchaseStatus", "purchased");
              localStorage.setItem("price_name", "AnnualVIP");
              localStorage.setItem("expirationTime", expirationTime.toISOString());
            }
          }
        }
      });

      let itemsList = [{ priceId: priceId, quantity: 1 }];

      // eslint-disable-next-line no-undef
      Paddle.Checkout.open({
        items: itemsList
      });
    }
  }
};
</script>

<style scoped>
.plan-card-daily{
  text-align: center;
  background-color: #bdbfc5;
}

.plan-card-monthly{
  text-align: center;
  background-color: #604800;
  color: aquamarine;
}

.plan-card-quartely{
  text-align: center;
  background-color: #494826;
  color:#f0f5ff;
}

.plan-card-annual{
  text-align: center;
  background-color: #000000;
  color: #fff700;
}

img {
  /* 设置图像宽度 */
  width: 38px;
  /* 设置图像高度 */
  height: 38px;
  /* 设置图像居中 */
  margin: 0 auto;
  /* 设置图像垂直居中 */
  display: block;
}
</style>
