// router.js

import { createRouter, createWebHistory } from 'vue-router';
import Service from './components/Service.vue';
import PricePage from './components/PricePage.vue'
// 导入你的组件
import HomePage from './components/HomePage.vue';
import MobileHomePage from './components/MobileHomePage.vue';
import AboutPage from './components/About.vue';
import SelectMask from './components/SelectMask.vue'
import TestPage from './components/Test.vue';
import AccountPage from './components/Account.vue';

let routes;

if (isMobile()){
  routes = [
    {
      path: '/',
      name: 'home',
      component: MobileHomePage
    },
    {
      path: '/about',
      name: 'about',
      component: AboutPage
    },
    {
      path: '/service',
      name: 'service',
      component: Service
    },
    {
      path: '/price',
      name: 'price',
      component: PricePage
    },
    {
      path: '/mask',
      name: 'mask',
      component: SelectMask
    },
    {
      path: '/test',
      name: 'test',
      component: TestPage
    },
    {
      path: '/account',
      name: 'account',
      component: AccountPage
    },
  ];
}else{
  routes = [
    {
      path: '/',
      name: 'home',
      component: HomePage
    },
    {
      path: '/about',
      name: 'about',
      component: AboutPage
    },
    {
      path: '/service',
      name: 'service',
      component: Service
    },
    {
      path: '/price',
      name: 'price',
      component: PricePage
    },
    {
      path: '/mask',
      name: 'mask',
      component: SelectMask
    },
    {
      path: '/test',
      name: 'test',
      component: TestPage
    },
    {
      path: '/account',
      name: 'account',
      component: AccountPage
    },
  ];
}

const router = createRouter({
  history: createWebHistory(),
  routes
});

function isMobile() {
  return window.innerWidth < 768; // Adjust breakpoint as needed
}

export default router;
