// config.js

// Paddle BearToken
export const config = {
  token: '234ab8f3f221400f52b69bfc6c512145ef4830c5b7f9aa0660'
};


export const productDaysMap = {
  "DailyPlan": 1,
  "MonthlyPlan": 31,
};

