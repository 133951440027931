<template>
  <div class="common-layout">
    <!-- Data -->
    <el-row :gutter="10">
      <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
        <div>
          <el-button type="warning" @click="priceVisible = true">{{ $t('Price') }}</el-button>
        </div>
        <div>
          <h2>{{ $t('Data') }}</h2>
          <el-input v-model="key" :placeholder="$t('Enter key')" clearable></el-input>
          <el-input v-model="value" :placeholder="$t('Enter value')" clearable></el-input>
          <el-button type="primary" @click="addData" round>{{ $t('Add Data') }}</el-button>
        </div>
        <span></span>
        <div>
          <el-scrollbar max-height="60vh">
            <el-table :data="chartData" style="width: 100%">
              <el-table-column prop="name" :label="$t('Name')">
                <template v-slot="{ row }">
                  <el-input v-model="row.name" @change="updateChartData(row)"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="value" :label="$t('Value')">
                <template v-slot="{ row }">
                  <el-input v-model="row.value" @change="updateChartData(row)"></el-input>
                </template>
              </el-table-column>
              <el-table-column :label="$t('Operations')" width="180">
                <template v-slot="{ row }">
                  <el-button type="danger" @click="deleteData(row)">{{ $t('Delete') }}</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-scrollbar>
        </div>
        <span></span>
        <el-select v-model="selectedLang" @change="changeLang" placeholder="Select Language">
          <el-option v-for="(item, index) in Object.values($i18n.messages.tag_label)" :key="index" :label="item"
            :value="$i18n.messages.label_tag[item]"></el-option>
        </el-select>
        <p>
          <el-button plain @click="serviceVisible = true">{{ $t('Service') }}</el-button>
        </p>
        <p>
          Copyright © emperinter
        </p>

      </el-col>

      <!-- PieChartMaster -->
      <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
        <div style="display: flex; background-color: black; justify-content: center; align-items: center; height:98vh">
          <div>
            <div v-if="isSubscribed" ref="chart"
              :style="{ backgroundColor: backgroundColor, width: imageWidth / 2 + 'px', height: imageHeight / 2 + 'px' }">
            </div>
            <div v-else>
              <img :src="imageUrl"
                :style="{ width: imageWidth + 'px', height: imageHeight + 'px', backgroundColor: backgroundColor }">
            </div>
          </div>
        </div>
      </el-col>

      <!-- Options -->
      <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
        <el-scrollbar height="98vh">
          <div>
            <h1>{{ $t('Options') }}</h1>
            <!-- <el-button type="danger" @click="updateChart" round>
              {{ $t('Generate') }}<el-icon class="el-icon--right">
                <Refresh />
              </el-icon>
            </el-button> -->
            <!-- <br> -->
            <el-button type="success" @click="exportLayout" round>
              {{ $t('OutPut') }}<el-icon class="el-icon--right">
                <Upload />
              </el-icon>
            </el-button>
            <el-divider></el-divider>
            <!-- Image -->
            <h2>{{ $t('Image') }}</h2>
            <p>{{ $t('imageWidth') }}</p>
            <el-input-number v-model="imageWidth" :min="10" :max="10000" :label="$t('imageWidth')"></el-input-number>
            <!-- <el-slider v-model="imageWidth"/> -->
            <p>{{ $t('imageHeight') }}</p>
            <el-input-number v-model="imageHeight" :min="10" :max="10000" :label="$t('imageHeight')"></el-input-number>
            <!-- <el-slider v-model="imageHeight"/> -->
            <p>{{ $t('backgroundColor') }}</p>
            <el-color-picker v-model="backgroundColor" show-alpha :predefine="predefineColors"
              :label="$t('Background Color')" size="large"></el-color-picker>
            <p>{{ $t('roseType') }}</p>
            <el-select v-model="roseType" placeholder="roseType">
              <el-option v-for="label in roseTypeOptions" :key="label.value" :label="this.$t(label.label)"
                :value="label.value"></el-option>
            </el-select>
            <el-divider></el-divider>
            <p>{{ $t('startRadius') }}</p>
            <el-input-number v-model="startRadius" :min="0" :max="100" :label="$t('startRadius')"></el-input-number>
            <el-slider v-model="startRadius" />
            <p>{{ $t('endRadius') }}</p>
            <el-input-number v-model="endRadius" :min="0" :max="100" :label="$t('endRadius')"></el-input-number>
            <el-slider v-model="endRadius" />

            <!-- Label -->
            <h2>{{ $t('LabelSet') }}</h2>
            <p>{{ $t('isPieLabelPresented') }}</p>
            <el-switch v-model="isPieLabelPresented" class="ml-2"
              style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
            <p>{{ $t('selectedLabelPosition') }}</p>
            <el-select v-model="selectedLabelPosition" placeholder="Label Position">
              <el-option v-for="label in labelPositions" :key="label.value" :label="this.$t(label.label)"
                :value="label.value"></el-option>
            </el-select>
            <p>{{ $t('labelFontSize') }}</p>
            <el-input-number v-model="labelFontSize" :min="1" :max="10000"
              :label="$t('labelFontSize')"></el-input-number>
            <el-slider v-model="labelFontSize" />
            <el-divider></el-divider>

            <h2>{{ $t('itemStyle') }}</h2>
            <p>{{ $t('pieItemBorderRadius') }}</p>
            <el-input-number v-model="pieItemBorderRadius" :min="0" :max="10000"
              :label="$t('pieItemBorderRadius')"></el-input-number>
            <el-slider v-model="pieItemBorderRadius" />
            <p>{{ $t('pieItemBorderWidth') }}</p>
            <el-input-number v-model="pieItemBorderWidth" :min="0" :max="10000"
              :label="$t('pieItemBorderWidth')"></el-input-number>
            <el-slider v-model="pieItemBorderWidth" />
            <p>{{ $t('pieItemBorderColor') }}</p>
            <el-color-picker v-model="pieItemBorderColor" show-alpha :predefine="predefineColors"
              :label="$t('pieItemBorderColor')" size="large"></el-color-picker>
          </div>
        </el-scrollbar>
      </el-col>
    </el-row>

    <el-dialog v-model="maskVisible" :title="$t('Masks')" width="500" :before-close="handleClose">
      <div v-if="this.hasPurchased()">
        <SelectMask @mask-selected="handleMaskSelected" />
      </div>
      <div v-else>
        <el-tabs :tab-position="tabPosition">
          <el-tab-pane :label="$t('Plan')">
            <PricePage />
          </el-tab-pane>
          <el-tab-pane :label="$t('Account')">
            <Account />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>

    <el-dialog v-model="priceVisible" :title="$t('')" width="500" :before-close="handleClose">
      <el-tabs :tab-position="tabPosition" label-width="250px">
        <el-tab-pane :label="$t('Plan')">
          <PricePage />
        </el-tab-pane>
        <span></span>
        <el-tab-pane :label="$t('Account')">
          <Account />
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <el-dialog v-model="serviceVisible" :title="$t('Service')" width="500" :before-close="handleClose">
      <Service />
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import { ElInput, ElButton, ElInputNumber, ElColorPicker, ElNotification } from 'element-plus';
import { Upload } from '@element-plus/icons-vue'
import SelectMask from './SelectMask.vue';
import { ref } from 'vue'
import html2canvas from 'html2canvas';
import PricePage from './PricePage.vue';
import Service from './Service.vue';
import Account from './Account.vue';

export default {
  name: 'HomePage',
  components: {
    ElInput,
    ElButton,
    ElInputNumber,
    ElColorPicker,
    SelectMask,
    Upload,
    PricePage,
    Service,
    Account
  },
  setup() {
    const predefineColors = ref([
      '#ff4500',
      '#ff8c00',
      '#ffd700',
      '#90ee90',
      '#00ced1',
      '#1e90ff',
      '#c71585',
      'rgba(255, 69, 0, 0.68)',
      'rgb(255, 120, 0)',
      'hsv(51, 100, 98)',
      'hsva(120, 40, 94, 0.5)',
      'hsl(181, 100%, 37%)',
      'hsla(209, 100%, 56%, 0.73)',
      '#c7158577',
    ]);

    const activeName = ref('first');
    const tabPosition = ref('top');
    return {
      predefineColors,
      activeName,
      tabPosition
    };
  },
  data() {
    return {
      chartData: [], // 初始化一批数据
      key: '',
      value: '',
      editingIndex: -1,
      backgroundColor: '#FFFFFF',
      maskVisible: false,
      imageHeight: 1024,
      imageWidth: 1024,
      priceVisible: false,
      serviceVisible: false,
      selectedLang: null,
      isSubscribed: true,
      startRadius: 10,
      endRadius: 80,
      isPieLabelPresented: true,
      selectedLabelPosition: 'inside',
      labelPositions: [
        { label: "outside", value: "outside" },
        { label: "inside", value: "inside" }
      ],
      labelFontSize: 18,
      pieItemBorderColor: '#e00707',
      pieItemBorderRadius: 18,
      pieItemBorderWidth: 10,
      roseType: '',
      roseTypeOptions: [
        { label: 'Normal', value: '' },
        { label: 'Rose', value: 'rose' },
      ],
      selectedFont: '',
      fontOptions: [
        { label: 'Inter', value: 'Inter, sans-serif' },
        { label: 'Helvetica Neue', value: '\'Helvetica Neue\', sans-serif' },
        { label: 'Helvetica', value: 'Helvetica, sans-serif' },
        { label: 'PingFang SC', value: '\'PingFang SC\', sans-serif' },
        { label: 'Hiragino Sans GB', value: '\'Hiragino Sans GB\', sans-serif' },
        { label: 'Microsoft YaHei', value: '\'Microsoft YaHei\', sans-serif' },
        { label: '微软雅黑', value: '微软雅黑, sans-serif' },
        { label: 'Arial', value: 'Arial, sans-serif' }
      ]
    };
  },
  created() {
    // 监听 imageHeight 变化
    this.$watch('imageHeight', (newValue, oldValue) => {
      console.log('imageHeight 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    // 监听 imageWidth 变化
    this.$watch('imageWidth', (newValue, oldValue) => {
      console.log('imageWidth 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('startRadius', (newValue, oldValue) => {
      console.log('startRadius 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('endRadius', (newValue, oldValue) => {
      console.log('endRadius 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('isPieLabelPresented', (newValue, oldValue) => {
      console.log('isPieLabelPresented 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('selectedLabelPosition', (newValue, oldValue) => {
      console.log('selectedLabelPosition 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('labelFontSize', (newValue, oldValue) => {
      console.log('labelFontSize 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('pieItemBorderColor', (newValue, oldValue) => {
      console.log('pieItemBorderColor 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('pieItemBorderRadius', (newValue, oldValue) => {
      console.log('pieItemBorderRadius 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('pieItemBorderWidth', (newValue, oldValue) => {
      console.log('pieItemBorderWidth 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('roseType', (newValue, oldValue) => {
      console.log('roseType 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('pieItemBorderColor', (newValue, oldValue) => {
      console.log('pieItemBorderColor 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
  },
  computed: {
    isInputValid() {
      console.log("isInputValid", this.key.trim() !== '' && this.value.trim() !== '');
      return this.key.trim() !== '' && this.value.trim() !== '';
    }
  },
  mounted() {
    this.selectedLang = this.$i18n.locale;
    this.hasPurchased();
    this.generateData();
    this.initChart();
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
    },
    initChart() {
      const chart = echarts.init(this.$refs.chart);
      const startRadius = this.startRadius;
      const endRadius = this.endRadius;
      const mroseType = this.roseType;
      const isPieLabelPresented = this.isPieLabelPresented;
      const selectedLabelPosition = this.selectedLabelPosition;
      const labelFontSize = this.labelFontSize;
      const pieItemBorderColor = this.pieItemBorderColor;
      const pieItemBorderRadius = this.pieItemBorderRadius;
      const pieItemBorderWidth = this.pieItemBorderWidth;
      var option = {
        series: [{
          type: 'pie',
          shape: 'circle',
          radius: [startRadius + "%", endRadius + "%"],
          roseType: mroseType,
          label: {
            show: isPieLabelPresented,
            position: selectedLabelPosition,
            fontSize: labelFontSize,
            fontWeight: 'bold'
          },
          itemStyle: {
            borderRadius: pieItemBorderRadius,
            borderColor: pieItemBorderColor,
            borderWidth: pieItemBorderWidth
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          data: this.chartData
        }]
      };
      chart.setOption(option);
    },
    Notify() {
      ElNotification({
        title: this.$t("Warning"),
        message: this.$t("InvalidInput"),
        position: 'top-left',
        type: 'warning'
      })
    },
    hasPurchased() {
      const purchaseStatus = localStorage.getItem("purchaseStatus");
      const expirationTime = localStorage.getItem("expirationTime");
      const hasPurchased = purchaseStatus === "purchased" && expirationTime && new Date() < new Date(expirationTime);
      return hasPurchased;
    },
    addData() {
      if (this.hasPurchased()) {
        if (this.key && this.value) {
          if (this.editingIndex > -1) {
            // 编辑已有数据
            this.chartData[this.editingIndex] = { name: this.key, value: this.value };
            this.editingIndex = -1;
          } else {
            // 添加新数据
            this.chartData.push({ name: this.key, value: this.value });
          }
          this.key = '';
          this.value = '';
          this.updateChart();
        } else {
          this.Notify();
        }
      } else {
        this.priceVisible = true;
      }
    },
    generateData() {
      // 初始化一批数据
      this.chartData = [
        { name: 'P', value: 1000 },
        { name: 'C', value: 950 },
        { name: 'H', value: 900 },
        { name: 'A', value: 850 },
        { name: 'R', value: 800 },
        { name: 'T', value: 750 },
        { name: 'M', value: 700 },
        { name: 'A', value: 650 },
        { name: 'S', value: 600 },
        { name: 'T', value: 550 },
        { name: 'R', value: 500 }
      ];
      this.updateChart();
    },
    updateChart() {
      if (this.hasPurchased()) {
        const chart = echarts.init(this.$refs.chart);
        const startRadius = this.startRadius;
        const endRadius = this.endRadius;
        const mroseType = this.roseType;
        const isPieLabelPresented = this.isPieLabelPresented;
        const selectedLabelPosition = this.selectedLabelPosition;
        const labelFontSize = this.labelFontSize;
        const pieItemBorderColor = this.pieItemBorderColor;
        const pieItemBorderRadius = this.pieItemBorderRadius;
        const pieItemBorderWidth = this.pieItemBorderWidth;
        var option = {
          series: [{
            type: 'pie',
            shape: 'circle',
            radius: [startRadius + "%", endRadius + "%"],
            roseType: mroseType,
            label: {
              show: isPieLabelPresented,
              position: selectedLabelPosition,
              fontSize: labelFontSize,
              fontWeight: 'bold'
            },
            itemStyle: {
              borderRadius: pieItemBorderRadius,
              borderColor: pieItemBorderColor,
              borderWidth: pieItemBorderWidth
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: this.chartData
          }]
        };
        chart.setOption(option);
      } else {
        this.priceVisible = true;
      }
    },
    editData(index) {
      // 编辑数据
      this.editingIndex = index;
      this.key = this.chartData[index].name;
      this.value = this.chartData[index].value;
    },
    updateChartData(row) {
      const index = this.chartData.findIndex(item => item === row);
      if (index !== -1) {
        this.chartData[index] = { ...row };
      }
      this.updateChart();
    },
    deleteData(row) {
      const index = this.chartData.findIndex(item => item === row);
      if (index !== -1) {
        this.chartData.splice(index, 1);
      }
      this.updateChart();
    },
    handleMaskSelected(mask) {
      // 接收口罩选择事件，并更新选定的口罩数据
      this.selectedMask = mask;
      this.updateChart();
      this.maskVisible = false;
    },
    exportLayout() {
      if (this.hasPurchased()) {
        const chartElement = this.$refs.chart;
        // 使用HTML2Canvas将DOM元素转换为图片
        html2canvas(chartElement, {
          backgroundColor: this.backgroundColor, // 设置背景颜色
          logging: false // 可选，禁用HTML2Canvas日志输出
        }).then(canvas => {
          // 将Canvas转换为图片URL
          const dataURL = canvas.toDataURL('image/png');
          // 创建一个下载链接并设置图片URL
          const a = document.createElement('a');
          a.href = dataURL;
          const date = new Date();
          const dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
          a.download = `wordcloud_${dateString}.png`;
          // 模拟点击下载链接
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      }else{
        this.priceVisible = true;
      }
    }
  }
};
</script>

<style>
.common-layout {
  margin-top: 0px;
  margin-bottom: 0px;
  height: 100%;
}

.el-alert {
  margin: 20px 0 0;
}

.el-alert:first-child {
  margin: 0;
}


/* .demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.el-tabs--right .el-tabs__content,
.el-tabs--left .el-tabs__content {
  height: 100%;
} */
</style>
